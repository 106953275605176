import { FormSpec, localized } from "../../form-specs";
import { Locale, LocaleApi, Page, PageApi, Site, SiteApi, WithId } from "@maxxton/cms-api";
import { LocalizedPageLink, SitemapPageLinkWidgetOptions } from "./sitemap.types";
import { SitemapWidgetSpec, Widget } from "../";
import { getI18nLocaleObject, getI18nLocaleString } from "../../i18n";

import { CMSProvidedProperties } from "../../containers/cmsProvider.types";
import { PrefetchAppParams } from "../../app.types";
import type { Route } from "../../routing/routing.types";
import { WidgetGroup } from "../widget.enum";
import { autocompleteSiteSpec } from "../../form-specs/models/autocompleteSite";
import { getRouteForPage } from "../../routing/routing.util";
import { memoize } from "lodash";
import namespaceList from "../../i18n/namespaceList";
import { pageSpec } from "../../form-specs/models/page";

const widgetOptionsForm: FormSpec<SitemapPageLinkWidgetOptions> = {
    id: "sitemap-page-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginSitemap, "sitemapPageOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginSitemap, "sitemapPageOptions"),
    properties: [
        {
            variable: "pageId",
            label: getI18nLocaleObject(namespaceList.pluginSitemap, "page"),
            type: "reference",
            refType: pageSpec,
        },
        {
            variable: "home",
            type: "checkbox",
            label: getI18nLocaleObject(namespaceList.pluginSitemap, "home"),
            default: false,
        },
        localized({
            variable: "localizedFriendlyUrl",
            tabContent: [
                {
                    variable: "friendlyUrl",
                    label: getI18nLocaleObject(namespaceList.pluginSitemap, "friendlyUrl"),
                    type: "text",
                    required: true,
                },
            ],
            visible: (ops: SitemapPageLinkWidgetOptions) => !ops.home,
        }),
        {
            variable: "friendlyUrl",
            label: getI18nLocaleObject(namespaceList.pluginSitemap, "friendlyUrl"),
            visible: (ops: SitemapPageLinkWidgetOptions) => !ops.home,
            type: "text",
            required: true,
        },
        localized({
            label: getI18nLocaleObject(namespaceList.pluginSitemap, "languages"),
            variable: "localized",
            tabContent: [
                {
                    variable: "useAsExternal",
                    label: getI18nLocaleObject(namespaceList.pluginMenu, "useAsExternal"),
                    type: "checkbox",
                    default: false,
                },
                {
                    variable: "url",
                    label: getI18nLocaleObject(namespaceList.pluginMenu, "externalLink"),
                    type: "text",
                    visible: (options: LocalizedPageLink) => options.useAsExternal,
                },
                {
                    label: getI18nLocaleObject(namespaceList.pluginSitemap, "site"),
                    variable: "siteId",
                    type: "autocomplete",
                    default: "",
                    refType: autocompleteSiteSpec,
                    visible: (options: LocalizedPageLink) => !options.useAsExternal,
                },
                {
                    label: getI18nLocaleObject(namespaceList.pluginSitemap, "page"),
                    variable: "pageId",
                    type: "autocomplete",
                    default: "",
                    refType: pageSpec,
                    dependsOnSiteSpec: "siteId",
                    visible: (options: LocalizedPageLink) => !options.useAsExternal,
                },
            ],
        }),
    ],
};

function getPageById(pageId: string): Promise<Page | null> {
    return PageApi.findByIdDebounced({ id: pageId, projection: { name: 1 } });
}

const memoizedGetPageById = memoize(getPageById);

export const pageLinkWidget: SitemapWidgetSpec<SitemapPageLinkWidgetOptions> = {
    id: "sitemap-page",
    type: "sitemap",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.pluginSitemap, "sitemapPageWidget"),
    description: getI18nLocaleObject(namespaceList.pluginSitemap, "sitemapPageWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): SitemapPageLinkWidgetOptions => ({
        friendlyUrl: "",
        home: false,
        pageId: null,
        localized: [],
        localizedFriendlyUrl: [],
    }),
    async instanceDescription({ widget }): Promise<string> {
        const { pageId } = widget.options;
        const page: Page | null = pageId ? await memoizedGetPageById(pageId) : null;
        if (!page) {
            return getI18nLocaleString(namespaceList.pluginSitemap, "noPage");
        }
        return page.name;
    },
    async instanceAdditionalInfo({ widget }): Promise<string> {
        let site: (Site & WithId) | null = null;
        const array = window.location.href.indexOf("/webmanager/site/edit/") ? window.location.href.split("/") : [];
        const siteId = array.length > 0 ? array[array.length - 1].replace("#", "") : "";
        if (siteId && siteId.length === 24) {
            site = await SiteApi.findById({
                id: siteId,
                projection: { sitemap: 0 },
            });
        }
        const { home, friendlyUrl, localizedFriendlyUrl } = widget.options;
        if (home) {
            return getI18nLocaleString(namespaceList.pluginSitemap, "sitemapHomepage");
        }
        if (site?.enableMultiLanguage && localizedFriendlyUrl?.length) {
            const localeFriendlyUrl = localizedFriendlyUrl.find((lf) => (site ? lf.locale === site.locale._id : false));
            if (localeFriendlyUrl) {
                return getI18nLocaleString(namespaceList.pluginSitemap, "sitemapFriendlyurl") + localeFriendlyUrl.friendlyUrl;
            }
            return getI18nLocaleString(namespaceList.pluginSitemap, "sitemapFriendlyurl") + friendlyUrl;
        }
        // eslint-disable-next-line max-len
        return friendlyUrl ? getI18nLocaleString(namespaceList.pluginSitemap, "sitemapFriendlyurl") + friendlyUrl : "";
    },
    // This method creates instances that are available for creating the Sitemap of a Site
    async instances(): Promise<SitemapPageLinkWidgetOptions[]> {
        const fetchedData = await Promise.all([LocaleApi.find(), PageApi.find({ query: { postId: { $exists: false } }, projection: { name: 1 } })]);
        let [locales] = fetchedData;
        const [, pages] = fetchedData;
        const array = window.location.href.indexOf("/webmanager/site/edit/") ? window.location.href.split("/") : [];
        const siteId = array.length > 0 ? array[array.length - 1].replace("#", "") : "";
        let site: (Site & WithId) | null = null;
        if (siteId?.length === 24) {
            site = await SiteApi.findById({ id: siteId, projection: { sitemap: 0 } });
        }

        if (site) {
            let languages: any[] = [];
            const allowedLanguages = site.localeMultiSelect;
            if (allowedLanguages && allowedLanguages.length > 0) {
                const siteLocales = allowedLanguages.map((localeObject) => localeObject!.value);
                languages = locales.filter((loc) => siteLocales.indexOf(loc._id) > -1);
                locales = languages;
            }
        }

        return pages.map((page) => ({
            friendlyUrl: `/${page.name.replace(/\s-*\s|\s/g, "-").toLowerCase()}`,
            home: false,
            pageId: page._id,
            localizedFriendlyUrl:
                site && site.enableMultiLanguage
                    ? locales.map((locale) => ({
                          locale: locale._id,
                          // eslint-disable-next-line max-len
                          friendlyUrl: `${site && locale.code === site.locale.code ? "" : "/" + locale.code}/${page.name.replace(/\s-*\s|\s/g, "-").toLowerCase()}`,
                      }))
                    : [],
            localized: [],
        }));
    },
    async routes(widget: Widget<SitemapPageLinkWidgetOptions>, context: CMSProvidedProperties, device?: PrefetchAppParams["device"]): Promise<Route[]> {
        const { pageId, friendlyUrl, home, localizedFriendlyUrl } = widget.options;
        if (pageId == null) {
            return [];
        }
        let routes: Route[] = [];
        if (context.site.enableMultiLanguage) {
            const isLocalizedFriendlyUrl = localizedFriendlyUrl && localizedFriendlyUrl.length > 0 && localizedFriendlyUrl.every((lf) => lf.friendlyUrl !== "" || lf.friendlyUrl !== undefined);
            if (isLocalizedFriendlyUrl) {
                const locales = localizedFriendlyUrl ? await Promise.all(localizedFriendlyUrl.map((lf) => context.cmsApi.localeApi.findById({ id: lf.locale }))) : [];

                // All locales relevant to a particular Site
                let allowedLocales: Array<(Locale & WithId) | null> = [];
                if (context.site.localeMultiSelect && context.site.localeMultiSelect.length > 0) {
                    const selectedSiteLocales = context.site.localeMultiSelect.map((localeObject) => localeObject.value);
                    allowedLocales.push(...locales.filter((loc) => loc && selectedSiteLocales.indexOf(loc._id) > -1));
                } else {
                    allowedLocales = locales;
                }

                const onlyLocaleIds = allowedLocales.map((al) => al?._id);

                const filteredLocalizedFriendlyUrl = localizedFriendlyUrl?.filter((lf) => onlyLocaleIds.indexOf(lf.locale) > -1) || [];

                routes = filteredLocalizedFriendlyUrl.map((lf) => {
                    const index = onlyLocaleIds.indexOf(lf.locale);
                    const presentLocale = allowedLocales[index]!.code;
                    const isDefaultLocale = presentLocale === context.site.locale.code;
                    return getRouteForPage({ home, isDefaultLocale, presentLocale, friendlyUrl: lf.friendlyUrl, pageId, context, widget, isLocalized: true, deviceType: device });
                });
            } else {
                const locales: Array<Locale & WithId> = await context.cmsApi.localeApi.find();
                routes = locales.map((locale) => {
                    const presentLocale = locale.code;
                    const isDefaultLocale = presentLocale === context.site.locale.code;
                    return getRouteForPage({ home, isDefaultLocale, presentLocale, friendlyUrl, pageId, context, widget, deviceType: device });
                });
            }
        } else {
            routes = [getRouteForPage({ home, isDefaultLocale: true, presentLocale: "", friendlyUrl, pageId, context, widget, deviceType: device })];
        }
        return [...routes];
    },
};
